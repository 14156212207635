* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Grafik-font";
  src: local("Grafik-font"),
    url("./assets/fonts/Graphik-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Grafik-Bold";
  src: local("Grafik-Bold"),
    url("./assets/fonts/Graphik-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Grafik-Light";
  src: local("Grafik-Light"),
    url("./assets/fonts/Graphik-Light.otf") format("opentype");
}

body {
  /* background: #1c8ef9 !important; */
  min-height: 100vh;
  display: flex;
  font-family: "Grafik-Light";
  /* font-family: "Fira Sans", sans-serif; */
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Grafik-Bold";
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}
.continue-p {
  font-size: 12px;
  color: #b2b2b2;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
}

@media (min-width: 900px) {
  #intro-example {
    height: 70vh;
  }
  .w-1000 {
    width: 900px;
  }
  .tab-content > .active {
    display: block;
    text-align: left;
    padding: 30px;
  }
  .w-500 {
    max-width: 500px;
    margin: 0 auto;
  }
  .form-outline {
    flex: 1;
  }
  .py-5 .row {
    gap: 0;
  }
  i.fa-solid.fa-pen-to-square {
    position: absolute;
    right: 0;
    cursor: pointer;
  }
  .relt {
    position: relative;
  }
  .navbar-brand img {
    width: 250px;
  }
}

li.nav-item {
  font-size: 20px;
}
.row {
  gap: 10px;
}
.mask {
  padding: 10px;
}
@media (max-width: 760px) {
  .credit-card {
    width: 100% !important;
    padding: 10px 10px !important;
  }
  .container .row {
    width: 100%;
    margin: 0 auto !important;
    padding: 0;
    gap: 20px;
  }
  .content h1 {
    font-size: 40px !important;
  }
  .d-flex {
    flex-direction: column !important;
  }
  .navbar-brand img {
    width: 140px;
  }
  .auth-inner {
    width: 95%;
  }
  #intro-example {
    height: 100vh;
  }
  li.nav-item {
    font-size: 20px;
    width: 50%;
  }
  .row {
    width: 100%;
    margin: 0 auto !important;
    padding: 10px;
  }
}

ul.navLink {
  display: flex;
  gap: 30px;
  list-style: none;
  margin: 0 20px;
}
li.link {
  color: black;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}
.jumb {
  background-image: url("../public/header.png");
  height: 100vh;
  text-align: left;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
}

.d-flex {
  display: flex;
  align-items: center;
}
.content {
  /* width: 50%; */
}
.content h1 {
  font-size: 50px;
}
.leftSection,
.rightSection {
  flex: 1;
}

.menu {
  text-align: left;
  background: #461e7b;
  position: absolute;
  width: 100%;
  color: white;
  height: 100vh;
  top: 50px;
  z-index: 99;
  overflow: hidden;
  transition: 0.4s;
}
a.mobMenu {
  color: white;
  font-size: 35px;
  text-transform: capitalize;
  margin-bottom: 20px;
}
li.menuItem {
  text-align: center;
  margin-bottom: 30px;
  border: 2px solid white;
}

.menu.container.hideMobile {
  height: 0;
}

.page-text {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 14px 80px rgba(34, 35, 58, 0.2);
  margin: auto;
  padding: 40px 55px 45px;
  transition: all 0.3s;
  max-width: 950px;
}

.photo {
  background: #f5f4f4;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 100px;
}

.photo img {
  width: 100px;
  height: 100px;
  border-radius: 1000px !important;
}

.loader {
  margin: 0 auto;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* cc */
.credit-card {
  margin: 0 auto;
  background-color: #0a4275;
  color: #ffffff;
  padding: 30px 30px;
  border-radius: 0.5rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  width: 450px;
  height: 250px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

/* .credit-card:hover {
  scale: 1.1;
} */

.head,
.number,
.tail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  z-index: 10;
}

.head {
  font-size: 1.3rem;
  font-weight: bold;
}

.number {
  margin-top: auto;
  font-size: 2.1rem;
  font-weight: bold;
  margin-bottom: 0.3rem;
  color: #d4d4d8;
}

.exp {
  font-size: 0.8rem;
  text-align: right;
}

.exp-date {
  color: #d4d4d8;
  font-size: 1.3rem;
}

.circle1 {
  position: absolute;
  width: 424px;
  height: 314px;
  border: solid 4px #a3a3a3;
  background-color: #1b4f7e;
  border-radius: 100%;
  transform: translateY(-22%) translateX(-55%);
}

.circle2 {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: #ff0000;
  border-radius: 100%;
  transform: translateY(190%);
}

.photo-cc {
  background-position: center;
  width: 120px;
  background: #e1e1e1;
  height: 120px;
  background-size: cover;
  border: solid #ff0000;
}

.bonus-cc {
  position: absolute;
  top: 10px;
  background: red;
  padding: 0 10px;
  border-radius: 10px;
  right: 6px;
  font-weight: 700;
  border: solid #ffff01;
}

/* why choose us  */
.section-head {
  margin-bottom: 60px;
}
.section-head h4 {
  position: relative;
  padding: 0;
  color: #451d7b;
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  margin-bottom: 30px;
}
.section-head h4:before {
  content: "";
  width: 60px;
  height: 3px;
  background: #451d7b;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}
.section-head h4 span {
  font-weight: 700;
  padding-bottom: 5px;
  color: #2f2f2f;
}
p.service_text {
  color: #cccccc !important;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}
.section-head p,
p.awesome_line {
  color: #818181;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}

.extra-text {
  font-size: 34px;
  font-weight: 700;
  color: #2f2f2f;
  margin-bottom: 25px;
  position: relative;
  text-transform: none;
}
.extra-text::before {
  content: "";
  width: 60px;
  height: 3px;
  background: #451d7b;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}
.extra-text span {
  font-weight: 700;
  color: #451d7b;
}
.item {
  background: #fff;
  text-align: center;
  padding: 30px 25px;
  -webkit-box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  border: 5px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.item:hover {
  background: #451d7b;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.item:hover .item,
.item:hover span.icon {
  background: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.item:hover h6,
.item:hover p {
  color: #fff;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.item .icon {
  font-size: 40px;
  margin-bottom: 25px;
  color: #451d7b;
  width: 90px;
  height: 90px;
  line-height: 96px;
  border-radius: 50px;
}
.item .feature_box_col_one {
  background: rgba(247, 198, 5, 0.2);
  color: #451d7b;
}
.item .feature_box_col_two {
  background: rgba(255, 77, 28, 0.15);
  color: #451d7b;
}
.item .feature_box_col_three {
  background: rgba(0, 147, 38, 0.15);
  color: #451d7b;
}
.item .feature_box_col_four {
  background: rgba(0, 108, 255, 0.15);
  color: #451d7b;
}
.item .feature_box_col_five {
  background: rgba(146, 39, 255, 0.15);
  color: #451d7b;
}
.item .feature_box_col_six {
  background: rgba(23, 39, 246, 0.15);
  color: #451d7b;
}
.item p {
  font-size: 15px;
  line-height: 26px;
}
.item h6 {
  margin-bottom: 20px;
  color: #2f2f2f;
}
.mission p {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
}
.mission i {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #451d7b;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
}
.mission .small-text {
  margin-left: 10px;
  font-size: 13px;
  color: #666;
}
.skills {
  padding-top: 0px;
}
.skills .prog-item {
  margin-bottom: 25px;
}
.skills .prog-item:last-child {
  margin-bottom: 0;
}
.skills .prog-item p {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}
.skills .prog-item .skills-progress {
  width: 100%;
  height: 10px;
  background: #e0e0e0;
  border-radius: 20px;
  position: relative;
}
.skills .prog-item .skills-progress span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #451d7b;
  width: 10%;
  border-radius: 10px;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.skills .prog-item .skills-progress span:after {
  content: attr(data-value);
  position: absolute;
  top: -5px;
  right: 0;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  padding: 3px 7px;
  border-radius: 30px;
}

@media (min-width: 992px) { 
  .unknown{
    text-align: center;  
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 32.333333% !important;
  }
}
